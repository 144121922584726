<template>
  <div class="enigma-container">
    <div class="canvas-container opacity-0">
      <canvas class="webgl-scene fixed top-0"></canvas>
    </div>
    <div class="code-pattern opacity-0 transition duration-1000" :class="{'opacity-100 z-10': revealCode}"></div>
    <overlay-navigation class="fixed z-20 w-full h-full" :hide-info="true" :light-mode="revealCode" />
    <div class="empty-scroll-helper"></div>
  </div>
</template>

<script>
import OverlayNavigation from '@/components/OverlayNavigation.vue'
import * as THREE from 'three'
import gsap from "gsap"
import { clearThree } from "../../helpers";
export default {
  name: 'Enigma8',
  data () {
    return {
      revealCode: false,
      scene: null
    }
  },
  components: {
    OverlayNavigation
  },
  destroyed () {
    clearThree(this.scene)
  },
  mounted () {
    // Prevent pinch zoom for ios
    if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
      window.document.addEventListener('touchmove', e => {
        if(e.scale !== 1) {
          e.preventDefault();
        }
      }, {passive: false});
    }

    // Trackings
    this.$gtmTracking.pageView()
    this.$mmTro.sendRtg()

    let tlContainer = gsap.timeline()
    tlContainer.to('.canvas-container', {duration: 4, opacity: 1})
    // Canvas
    const canvas = document.querySelector('canvas.webgl-scene')
    // Scene
    this.scene = new THREE.Scene()
    // Fog
    const fog = new THREE.Fog('#000000', 1, 55)
    this.scene.fog = fog

    // Cube
    const textureLoader = new THREE.TextureLoader();

    const texture0 = textureLoader.load( '/assets/cylindre/rocher.jpg' );

    const textureText = textureLoader.load( '/assets/cylindre/baseColor.jpg' );

    const materials = [
        new THREE.MeshBasicMaterial( { map: texture0, side: THREE.BackSide} ),
        new THREE.MeshBasicMaterial( { map: textureText, side: THREE.BackSide} ),
        new THREE.MeshBasicMaterial( { map: textureText, side: THREE.BackSide} ),
        new THREE.MeshBasicMaterial( { map: textureText, side: THREE.BackSide} ),
        new THREE.MeshBasicMaterial( { map: textureText, side: THREE.BackSide} ),
        new THREE.MeshBasicMaterial( { map: textureText, side: THREE.BackSide} )
    ];
    let faceMaterial = new THREE.MeshFaceMaterial( materials );
    
    
    let geometry = new THREE.BoxGeometry(65, 6, 6, 1, 1, 1)
    let cube = new THREE.Mesh(
        geometry,
        faceMaterial);
    
    cube.scale.x = -1
    cube.rotation.x = Math.PI * 0.25
    this.scene.add(cube);

    // Size
    const sizes = {
        width: window.innerWidth,
        height: window.innerHeight
    }

    // Scroll event
    window.addEventListener("scroll", () => {
      camera.position.x = 10 - window.scrollY / 500.0;
      if (camera.position.x < -30 && !this.revealCode) {
        this.revealCode = true
      } else if (this.revealCode && camera.position.x >= -30) {
        this.revealCode = false
      }
    });

    // Responsive
    window.addEventListener('resize', () =>
    {
      // Update sizes
      sizes.width = window.innerWidth
      sizes.height = window.innerHeight

      // Update camera
      camera.aspect = sizes.width / sizes.height
      camera.updateProjectionMatrix()

      // Update renderer
      renderer.setSize(sizes.width, sizes.height)
      renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    })

    // Base camera
    const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
    camera.rotation.y = Math.PI * 0.5
    camera.position.x = 10 - window.scrollY / 500.0;
    this.scene.add(camera)

    /**
     * Renderer
     */
    const renderer = new THREE.WebGLRenderer({
        canvas: canvas,
        alpha: true
    })
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

    /**
     * Animate
     */
    const clock = new THREE.Clock()
    let previousTime = 0

    const tick = () =>
    {
        const elapsedTime = clock.getElapsedTime()
        const deltaTime = elapsedTime - previousTime
        previousTime = elapsedTime

        // Render
        renderer.render(this.scene, camera)

        // Call tick again on the next frame
        window.requestAnimationFrame(tick)
    }

    tick()
  }
}
</script>

<style lang="scss" scoped>
.enigma-container {
  overflow: hidden;
  height: 100%;
  .canvas-container {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
  }
  .code-pattern {
    background: url('/assets/cylindre/code-pattern-min.jpg') top center;
    background-size: cover;
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
    @media screen and (min-width: 768px) {
      background: url('/assets/cylindre/code-pattern.jpg') top center;
      background-size: cover;
    }
  }
}
.empty-scroll-helper {
  width: 100%;
  height: 22000px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
</style>